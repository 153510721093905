import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useLaborTickets } from '../utils/hooks';
import NewLaborTicket from './NewLaborTicket';
import Palette from './Palette';
import { Box, Heading, Text, Button, Flex, Badge } from '@chakra-ui/react';

const LaborItems = ({
  activity,
  activitySet,
  activityData,
  timezone,
  employees,
  workOrderOperation,
  firstActive,
  lastActive,
  ...props
}) => {
  const { laborTickets, refetch } = useLaborTickets(activitySet, activity);
  const [newTickets, setNewTickets] = useState([]);

  const handleNewTicket = () => {
    const newTicket = { id: Date.now() }; // Using timestamp as a unique ID
    setNewTickets((prevTickets) => [...prevTickets, newTicket]);
  };

  const handleRemoveTicket = (id) => {
    setNewTickets((prevTickets) =>
      prevTickets.filter((ticket) => ticket.id !== id)
    );
  };

  const onInsert = () => {
    refetch();
  };

  return (
    <Box>
      {laborTickets.map((ticket) => (
        <Box key={ticket.transactionId}>
          <Flex justify="space-between" align="center" mt="0px" mb="4px">
            <Heading as="h3" size="sm">
              Existing Labor Ticket
            </Heading>
          </Flex>
          <Box
            bg={ticket.type === 'RUN' ? Palette.Green100 : Palette.Yellow100}
            borderWidth="1px"
            borderColor="#ccc"
            p="10px"
            pb="20px"
          >
            <Text mb="4px">
              Transaction Id:{' '}
              {ticket.laborTicketId ? (
                ticket.laborTicketId
              ) : (
                <Badge
                  borderRadius="12px"
                  px="2"
                  py="1"
                  fontSize="0.75em"
                  color="black"
                  bg={
                    ticket.laborTicketId ? Palette.Green400 : Palette.Yellow500
                  }
                  ml="4px"
                >
                  Pending
                </Badge>
              )}
            </Text>
            <Text mb="4px">
              {DateTime.fromISO(ticket.clockIn)
                .setZone(timezone)
                .toLocaleString(DateTime.DATETIME_SHORT)}{' '}
              -{' '}
              {DateTime.fromISO(ticket.clockOut)
                .setZone('America/New_York')
                .toLocaleString(DateTime.DATETIME_SHORT)}
            </Text>
            <Text mb="4px">Good Parts: {ticket.goodParts}</Text>
            <Text mb="4px">Bad Parts: {ticket.badParts}</Text>
            <Text mb="4px">Employee: {ticket.personId}</Text>
            <Text mb="4px">
              Labor Time:{' '}
              {DateTime.fromISO(ticket.clockOut)
                .diff(DateTime.fromISO(ticket.clockIn), 'hours')
                .hours.toFixed(2)}{' '}
              hours
            </Text>
          </Box>
        </Box>
      ))}
      {newTickets.map((ticket, index) => (
        <NewLaborTicket
          key={`${ticket.id}-${index}`}
          activity={activity}
          activitySet={activitySet}
          activityData={activityData}
          timezone={timezone}
          employees={employees}
          workOrderOperation={workOrderOperation}
          onInsert={onInsert}
          onRemove={() => handleRemoveTicket(ticket.id)}
          firstActive={firstActive}
          lastActive={lastActive}
          id={ticket.id}
        />
      ))}
      <Button size="xs" m="1" colorScheme="blue" onClick={handleNewTicket}>
        Add New Labor Ticket
      </Button>
    </Box>
  );
};

export default LaborItems;
