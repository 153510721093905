import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useSplitActivitySet } from '../utils/mmutils';
import SplitModal from './SplitModal';
import ExecutionBar from './ExecutionBar';
import Palette from './Palette';
import { Box, Heading, Text, Button, Flex } from '@chakra-ui/react';

const ActivityItem = ({
  activityData,
  activity,
  timezone,
  onActiveTimesUpdated,
  ...props
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const isMounted = useRef(true);
  const { splitActivitySet } = useSplitActivitySet();

  const endValue = useMemo(() => {
    return activity.endAt ? activity.endAt : DateTime.now().toISO();
  }, [activity.endAt]);

  const handleModalConfirm = (action, time) => {
    if (action === 'split') {
      splitActivitySet(activity)
        .then(() => {
          console.log('ActivitySet split successfully!');
        })
        .catch((err) => {
          console.error('Failed to split ActivitySet:', err);
        });
    } else if (action === 'time') {
      splitActivitySet(
        activity,
        DateTime.fromISO(time).setZone('utc').setZone(timezone)
      )
        .then(() => {
          console.log('ActivitySet split at time successfully!');
        })
        .catch((err) => {
          console.error('Failed to split at time: ', err);
        });
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (isMounted.current && !isDataLoaded) {
      setIsDataLoaded(true);
    }

    return () => {
      isMounted.current = false;
    };
  }, [isDataLoaded]);

  const [firstActive, setFirstActive] = useState();
  const [lastActive, setLastActive] = useState();
  const activeCallback = (first, last) => {
    setFirstActive(first?.toLocaleString(DateTime.TIME_SIMPLE));
    setLastActive(last?.toLocaleString(DateTime.TIME_SIMPLE));
    if (typeof onActiveTimesUpdated === 'function') {
      onActiveTimesUpdated(first, last);
    }
  };

  const productionHours =
    activityData && activityData.productionTime
      ? (activityData.productionTime / 3600000).toFixed(2)
      : 0;
  const setupHours =
    activityData && activityData.setupTime
      ? (activityData.setupTime / 3600000).toFixed(2)
      : 0;
  const activeHours =
    activityData && activityData.timeInCycle
      ? (activityData.timeInCycle / 3600000).toFixed(2)
      : 0;

  return (
    <Box p="8px" borderRadius="4px" mb="4px" bgColor="#f5f5f5" flex="1">
      <Flex justify="space-between" align="center" mt="0px" mb="4px">
        <Heading as="h3" size="sm">
          MachineMetrics Activity
        </Heading>
      </Flex>
      <Box
        p="10px"
        pb="20px"
        borderWidth="1px"
        borderColor="#ccc"
        bg={
          activity.activityType.name === 'Production'
            ? Palette.Green100
            : Palette.Yellow100
        }
      >
        <Text mb="4px">
          {DateTime.fromISO(activity.startAt)
            .setZone(timezone)
            .toLocaleString(DateTime.DATETIME_SHORT)}{' '}
          -{' '}
          {activity.endAt
            ? DateTime.fromISO(activity.endAt)
                .setZone(timezone)
                .toLocaleString(DateTime.DATETIME_SHORT)
            : 'In Progress'}
        </Text>
        <Text mb="4px">
          Good Parts: {activityData?.goodParts ? activityData.goodParts : 0}
        </Text>
        <Text mb="4px">
          Rejected Parts:{' '}
          {activityData?.rejectedParts ? activityData.rejectedParts : 0}
        </Text>
        <Text mb="4px">
          {activity.activityType.name === 'Setup'
            ? `Setup Time: ${setupHours} hours`
            : `Production Time: ${productionHours} hours`}
        </Text>
        <Text mb="4px">Active Time: {activeHours} hours</Text>
        <Text mb="4px">
          First Active: {firstActive}, Last Active: {lastActive}
        </Text>
        <Box w="100%" h="10px">
          <ExecutionBar
            machineRef={activity.activitySet.machine.machineRef}
            start={activity.startAt}
            end={endValue}
            height={15}
            activeCallback={activeCallback}
          />
        </Box>
      </Box>
      {showModal && (
        <SplitModal
          activity={activity}
          onClose={() => setShowModal(false)}
          onConfirm={handleModalConfirm}
        />
      )}
    </Box>
  );
};

export default ActivityItem;
